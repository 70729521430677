import React, { lazy, Suspense, useEffect, useState } from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider, useTemplateContext } from 'components/post/TemplateProvider'

import SEO from "components/seo"
import Header from "components/header"
import Sections from "components/sections"
import Footer from "components/footer"
import Chip from 'components/shared/chip/chip.jsx'

// const AnimatedGradient = lazy(() => import("components/sections/impactArea/animatedGradient"))
const AnimatedGradient = lazy(() => import("components/shared/gradient"))
const Filters = lazy(() => import("components/filters/Filters"))
const QuoteBlock = lazy(() => import("components/post/blocks/quoteBlock"))

// TODO: Reduce the size of these modules
// And move to post/template provider
import { cities } from 'vibemap-constants/dist/helpers.js'
//import "components/sections/impactArea/impactArea.scss"
import "../components/post/post.scss"

const VibeTemplate = (props) => {

  const { pageContext, data } = props
  let {
    id,
    affirmations,
    definition,
    name,
    related } = pageContext

  const pageData = data.allWpPage.edges[0].node

  const textColor = 'black'

  // Instantiate filters state for provider
  // A little circular, as default are in provider
  // And provided as props to provider
  const context = useTemplateContext()

  // Keep track of city slug
  const [city, setCity] = useState('oakland')

  const [filters, setFilters] = useState(context.filters)

  // Handle filters here
  const onChange = (newFilters) => {
    setCity(newFilters.cities[0])
    setFilters(newFilters)
  }

  // FIXME: Work around for inconsistent source data
  if (typeof affirmations === 'string') affirmations = [affirmations]

  const mainVibe = typeof id == 'string' ? [id] : id

  related = related ? related : []
  // Add similar vibes from data to related

  const vibesQuery = mainVibe
  const relatedVibes = mainVibe.concat(related)

  let defaultFilters = context.filters

  // Override vibes filter
  useEffect(() => {
    if (vibesQuery) {
      defaultFilters.vibes = vibesQuery
      setFilters(defaultFilters)
    }
  }, [filters, context])

  let geoQuery = null
  const selectedCity = cities.filter(result => result.slug === city)
  geoQuery = selectedCity[0].location

  // And set city
  useEffect(() => {
    defaultFilters.city = [selectedCity[0].slug]
    setFilters(defaultFilters)
  }, [city])

  //vibeRelations[mainVibe].filter(vibe => console.log('filter this vibe: ', vibe))

  const quoteBlock = affirmations && ({
    name: 'acf-quotes',
    quoteCards: {
      quotes: affirmations.map(affirmation => {
        return {
          'quote': affirmation,
          'author': null
        }
      })
    }
  })

  const title = `${name} vibes`

  return (
    <div>
      <TemplateContextProvider city={city} filters={filters}>
        <SEO
          lang="en-US"
          title={title}
          data={pageData.seo} />
        <main>
          <Header />
          <div className="s-impact-area">
            <div className="container">
              <h1 className={`title c-${textColor}`}>
                {name + ' vibes'}
              </h1>
            </div>
            <div className="background">
              <Suspense fallback={null}>
                <AnimatedGradient
                  color1={pageContext.color1}
                  color2={pageContext.color2} />
              </Suspense>
            </div>
          </div>
          <Suspense fallback={null}>
            <Filters filters={filters} onChange={onChange} showVibes={false} />
          </Suspense>

          <div className="post-body">
            <div className="container">

              <section>
                <h3>{name} Vibes</h3>
                <span>adj.</span>
                <p>
                  {definition}
                </p>

                {related && Array.isArray(related) &&
                  <>
                    {related.map(vibe => (
                      <Chip
                        color={'black'}
                        key={vibe}
                        text={vibe} />
                    ))}
                  </>
                }

              </section>

              {pageData.sections.sections &&
                <Sections
                  data={pageData.sections.sections}
                  overrideQuery={{
                    vibe: vibesQuery,
                    relatedVibes: relatedVibes,
                    location: geoQuery
                  }} />
              }

              {quoteBlock &&
                <Suspense fallback={null}>
                  <QuoteBlock key='quote-block' block={quoteBlock} />
                </Suspense>
              }
            </div>
          </div>
        </main>
        <Footer />
      </TemplateContextProvider>
    </div>
  )
}
export default VibeTemplate

export const vibeQuery = graphql`
query vibeQuery {
  allWpPage(filter: {slug: {in: "vibe-page-template"}}) {
    edges {
      node {
        id
        title
        slug
        status
        date
        sections {
          sections {
            ... on WpPage_Sections_Sections_VibesBlock {
              advancedOptions
              customVibes
              fieldGroupName
              heading
              style
              vibes {
                name
              }
            }
            ... on WpPage_Sections_Sections_SingCardsBlock {
              fieldGroupName
              singCards {
                fieldGroupName
                heading
                style
                subheading
                vibemapSuggests
                posts {
                  placeType
                  type
                  vibeQuery {
                    name
                    slug
                  }
                  subcategory
                  geoQuery {
                    latitude
                    longitude
                  }
                  categoryQuery
                  distanceQuery
                  editorialCategoryQuery
                  numOfPlaces
                  searchQuery
                  fieldGroupName
                  city

                }
              }
            }
            ... on WpPage_Sections_Sections_NeighborhoodCardsBlock {
              fieldGroupName
              neighborhoodCards {
                automatic
                fieldGroupName
                heading
                subHeading
                vibeQuery {
                  name
                  slug
                }
              }
            }
            ... on WpPage_Sections_Sections_MapBlock {
              fieldGroupName
                customLocation
                fitBoundToMarkers
                heading
                height
                location {
                  ... on WpCity {
                    id
                    cityDetails {
                      placemarker {
                        latitude
                        longitude
                      }
                    }
                  }
                  ... on WpNeighborhood {
                    id
                    neighborhood {
                      map {
                        latitude
                        longitude
                      }
                    }
                  }
                }
                map {
                  latitude
                  longitude
                }
                heatmap
                directions
                map3d
                mapTheme
                markerStyle
                showMarkers
                width
                zoom
            }
            ... on WpPage_Sections_Sections_PostCardsBlock {
              automatic
              fieldGroupName
              heading
              subHeading
              vibeQuery {
                name
                slug
              }
            }

          }
        }
      }
    }
  }
}
`
